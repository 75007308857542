import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PaypalService {
    userLoggedInSubject = new Subject<any>();
    userLoggedInObservable: Observable<any>;
    constructor(
        private http: HttpClient
    ) {
    }

    checkPaypalConnected() {
        return this.http.get(environment.apiUrl + "/paypal-connect/paypal/redirecturl");
    }

    connectPaypal() {
        return this.http.get(environment.apiUrl + "/paypal-connect/paypal/redirecturl");
    }

    connectByCode(code) {
        return this.http.get(environment.apiUrl + '/paypal-connect/paypal/connect?code='+code);
    }

    disconnect() {
        return this.http.get(environment.apiUrl + "/paypal-connect/deskera/disconnect");
    }

    transferInvoice(code){
        return this.http.get(environment.apiUrl + '/paypal-connect/paypal/transfer?code='+code);
    }

}
