import { Component, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ViewCell, DefaultEditor } from 'ng2-smart-table';


@Component({
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.scss'],
})
export class CustomEditorComponent extends DefaultEditor implements AfterViewInit  {

    innerHTML: any;

    constructor(private cd: ChangeDetectorRef){
        super();
    }

    ngAfterViewInit() {
        const transformFx = this.cell.getColumn().getValuePrepareFunction()
        if(transformFx){
            if(this.cell.getColumn().id === 'type'){
                this.innerHTML = this.cell.getValue().innerHTML
            } else {
                this.innerHTML = transformFx(this.cell.getValue(),this.cell.getRow())
            }
        } else {
            this.innerHTML = this.cell.getValue();
        }
    }
}