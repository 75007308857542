import { Component, Input, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';
import { SmartTableData } from '../../@core/data/smart-table';
import { MigrateButtonComponent } from '../migrate-button/migrate-button.component';
import { NbDialogRef, NbDialogService, NbComponentStatus, NbToastrService, NB_DIALOG_CONFIG } from '@nebular/theme';
import { PaypalService } from '../paypal/services/paypal.service';
import { LocalStorageConstants } from '../../authentication/authentication.constants';
import { CustomEditorComponent } from '../custom-editor/custom-editor.component';
import { MigrateLinkComponent } from '../migrate-link/migrate-link.component';
import { TenantService } from '../../shared/services/tenant.service';
import { ITenants } from '../../models/tenant';
import { NumberUtil } from '../../common-utils/numberUtil';

@Component({
  selector: 'orders',
  styleUrls: ['./orders.component.scss'],
  templateUrl: './orders.component.html',
})
export class OrdersComponent {

  loading: boolean = false;
  editData: any;
  result: any[] = [];
  tenantInfo: ITenants;
  selectedCompany = "";
  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add : false,
      delete : false,
      edit: false,
    },
    columns: {
      documentSequenceCode: {
        title: 'Sales Invoice #',
        type: 'string',
        filter: false,
      },
      contactCode: {
        title: 'Contact',
        type: 'number',
        filter: false,
      },
      totalAmount: {
        title: 'Invoice Amount',
        type: 'html',
        filter: false,
        editor: {
          type: 'custom',
          component: CustomEditorComponent
        },
        valuePrepareFunction: (cell, row) => {
          return '<div class="tAmount">'+this.totalAmountValue(cell, row)+'</div>';
        },
      },
      invoiceStatus: {
        title: 'Status',
        type: 'html',
        filter: false,
        editor: {
            type: 'custom',
            component: CustomEditorComponent
        },
        valuePrepareFunction: this.checkStatus
      },
      customerEmail: {
        title: 'Customer Email',
        type: 'string',
        filter: false,
      },
      paypalLink: {
        title: 'Paypal Invoice Link',
        type: 'custom',
        filter: false,
        renderComponent: MigrateLinkComponent,
        valuePrepareFunction: (cell, row) => {
          return "INVOICE"
        },
        onComponentInitFunction: (instance) => {
          instance.updateResult.subscribe((selectedData) => {
            this.handleUpdatedUser(selectedData);
          });
        }
      },
    },
    noDataMessage: 'No Invoices Due',
  };

  source: LocalDataSource = new LocalDataSource();

  constructor(
    private service: SmartTableData,
    private tenantService: TenantService,
    private dialogService: NbDialogService,
  ) {
    this.loading = true;
    this.selectedCompany = localStorage.getItem(LocalStorageConstants.TENANT_NAME);
    const data = this.service.getOrderData().subscribe(
      (data: any) => {
        this.source.load(data.content);
        this.loading = false;
      }
    );
    this.getTenantInfo();
  }

  ngOnInit() {}
  getTenantInfo(): void {
		this.tenantService.get().subscribe((tenantResponse: ITenants) => {
        this.tenantInfo = tenantResponse;
    });
	}
  checkStatus(cell, row) {
      return `<div class="statusbox">` + cell + '</div>';
  }
  totalAmountValue(cell, row) {
    return NumberUtil.formatNumberWithCurrency(cell, this.tenantInfo.decimalScale, this.tenantInfo.numberFormat);
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  handleUpdatedUser(selectedData) {
    let rowData = selectedData.param2;
    this.source['data'].forEach(element => {
      if ((element.salesInvoiceCode == selectedData.param1.salesInvoiceCode)) {
        element['paypalId'] = rowData.paypalId;
        element['paypalLink'] = rowData.paypalLink;
      }
      if ((element.contactCode == selectedData.param1.contactCode)) {
        element['customerEmail'] = selectedData.param1.customerEmail;
      }
    });
    this.source.refresh();
  }
}

@Component({
  selector: 'edit-dialog',
  styleUrls: ['./orders.component.scss'],
  templateUrl: './edit.component.html',
})
export class EditDialog {
  @Input() data: any = [];
  result = [];
  isUpdateEmail = false;
  isSubmit = false;
  constructor(public dialogRef: NbDialogRef<EditDialog>,
    private toastrService: NbToastrService,
    private paypalService: PaypalService,

    @Inject(NB_DIALOG_CONFIG) dialogData: any) { }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close('');
  }

  submit() {
    if (this.data.customerEmail == undefined || this.data.customerEmail == '') {
      return;
    }
    this.isSubmit = true;
      const code = this.data.salesInvoiceCode
        + '&customerEmail=' + this.data.customerEmail + '&isUpdateEmail=' + this.data.isUpdateEmail;
      this.paypalService.transferInvoice(code).subscribe(
        (data: any) => {
            this.result = data;
            this.dialogRef.close(this.result);
            this.isSubmit = false;
        }, error => {
          const warningStatus: NbComponentStatus = 'danger';
          const config = {
            status: warningStatus,
          };
          this.toastrService.show(error.error.message, "", config);
          this.dialogRef.close('');
          this.isSubmit = false;
        });

    }
}
export const INVOICE_CODE = 'saleInvoiceCode';