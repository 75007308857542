import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbAuthSimpleToken, NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { NbToastrService, NbDialogService, NbComponentStatus } from '@nebular/theme';
import { PaypalService } from '../paypal/services/paypal.service';
import { EditDialog } from '../orders/orders.component';

@Component({
  selector: 'ngx-migrate-link',
  templateUrl: './migrate-link.component.html',
  styleUrls: ['./migrate-link.component.scss']
})
export class MigrateLinkComponent implements OnInit {

  public renderValue;

    @Input() value;
    @Input() rowData: any;
    user: NbAuthSimpleToken;
    hasBeenClicked: boolean = false;
    buttonClicked = false;
    editData: any;
    result: any[] = [];
    @Output() updateResult = new EventEmitter<{param1: any, param2: any}>();
    customerEmail = '';
    constructor(
        private http: HttpClient,
        private toastrService: NbToastrService,
        private authService: NbAuthService,
        private paypalService: PaypalService,
        private dialogService: NbDialogService,
    ) {

        this.authService.onTokenChange()
            .subscribe((token: NbAuthSimpleToken) => {

            if (token.isValid()) {
                this.user = token
            }

        });
    }

    ngOnInit() {
        this.renderValue = this.value;
        this.customerEmail = this.rowData.customerEmail;
    }

    onMigrateButtonClick(){
                this.transferPaypalInvoice();
    }

    transferPaypalInvoice(){
        this.hasBeenClicked = true;
        const code = this.rowData.salesInvoiceCode
        + '&customerEmail=' + this.rowData.customerEmail;
        this.paypalService.transferInvoice(code).subscribe(
            (data: any) => {
                if (data.href) {
                 // this.setCurrentStep(1)
                }
              }, error => {
                const warningStatus:NbComponentStatus = 'danger';
                const config = {
                    status: warningStatus,
                };
                this.toastrService.show(error.error.message, "", config);
              }
            )
    }
    onEditRowSelect(event) {
        this.result = [];
        this.editData = event;
        this.dialogService.open(EditDialog, {
            context: {
                data: this.editData,
            },
        }).onClose.subscribe(data => {
            if(data === '' || data == null || this.rowData.isUpdateEmail === undefined || !this.rowData.isUpdateEmail) {
                this.rowData.customerEmail = this.customerEmail;
             }
            this.rowData = data;
            this.result.push(data);
            this.updateResult.emit({param1:event,param2:this.rowData});

        });
    }

}
